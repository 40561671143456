import DataTable from 'datatables.net-dt';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import config from './auth_config';

let auth0Client = null;

const configureClient = async () => {  
    auth0Client = await createAuth0Client({
      domain: config.domain,
      clientId: config.clientId
    });
};

class Site {
    constructor() {
        window.MSD = {};     
        this.user = {}; 
        this.configureAuth()
        this.setupListeners(); 
        this.configureDataTable();
    }    

    async configureAuth() {
        await configureClient();
        window.MSD.login = this.login;

        const query = window.location.search;

        if (query.includes("code=") && query.includes("state=")) {
            await auth0Client.handleRedirectCallback();
            window.history.replaceState({}, document.title, "/");
        }

        this.updateUI();
    }

    setupListeners() {
        this.userButton = document.querySelector('.btn-user-avatar');
        this.userButton.addEventListener('click', this.toggleUserMenu.bind(this));
        this.avatar = document.querySelector('.avatar');
        this.userMenu = document.querySelector('.user-menu');
        this.loginButton = document.querySelector('.btn-login');
        this.loginButton.addEventListener('click', this.login);
        this.logoutButton = document.querySelector('.btn-logout');
        this.logoutButton.addEventListener('click', this.logout);
        this.accountButton = document.querySelector('.btn-account');
    }

    configureDataTable() {
        window.MSD.table = new DataTable('#data', {
            language: {
                lengthMenu: "Display _MENU_",
            },
            order: [[2, 'desc']],
            "pageLength": 10
        });  
    }

    async login() {
        await auth0Client.loginWithRedirect({ 
          authorizationParams: {
            redirect_uri: window.location.origin
          }
        });
        //window.location.reload();
    };

    async logout() {
        auth0Client.logout({
            logoutParams: {
              returnTo: window.location.origin
            }
        });
    };

    toggleUserMenu() {
        this.userMenu.classList.toggle('active');
    }

    async updateUI() {
        const isAuthenticated = await auth0Client.isAuthenticated();

        switch(isAuthenticated) {
            case true:
                this.loginButton.classList.remove('active');
                this.accountButton.classList.add('active');
                this.user = await auth0Client.getUser();
                this.avatar.src = this.user.picture;
                // const token = await auth0Client.getIdTokenClaims();
                // console.log(token.__raw);
                break;
            case false:
                this.loginButton.classList.add('active');
                this.accountButton.classList.remove('active');
                this.user = {};
                break;
        }
    }
}

export default Site;